import React from 'react';
import './App.css';
// import ComponentTpl from './components/componentTpl/ComponentTpl';
import BootstrapTpl from './components/BootstrapTpls/BootstrapTpl';

export default class App extends React.Component {
  componentDidMount () {
    const script = document.createElement("script");

    script.innerHTML = "var _paq = window._paq || [];_paq.push(['trackPageView']); _paq.push(['enableLinkTracking']);_paq.push(['setTrackerUrl', '//wp.armit.es/wp-content/plugins/matomo/app/matomo.php']);_paq.push(['setSiteId', '1']);var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];g.type='text/javascript'; g.async=true; g.defer=true; g.src='//wp.armit.es/wp-content/uploads/matomo/matomo.js'; s.parentNode.insertBefore(g,s);";
    script.async = true;

    document.body.appendChild(script);
}
  render() {
    return (
    <div className="App">
      <BootstrapTpl/>
    </div>
  );
}
}