/* eslint-disable no-restricted-globals */
import React, {Component} from 'react';
import SimpleSlider from "../SimpleSlider/SimpleSlider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhone, faMapMarkerAlt, faHeart, faLaptopMedical, faLaptopCode, faServer , faBullhorn} from '@fortawesome/free-solid-svg-icons'
import SliderTech from "../SliderTech/SliderTech";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ContactForm from "../ContactForm/ContactForm";
//import pics from './assets/img/portfolio/thumbnails';
import './BootstrapTpl.css';

class BootstrapTpl extends Component {

  constructor (props) {
    super(props);
    this.state = {
      className: ''
    }
  }  

  handleScroll = () => {
    if (window.pageYOffset > 0) {
      if(!this.state.className) {
        this.setState({ className: "navbar-scrolled" });
      }
    }else {
      if(this.state.className){
        this.setState({ className: "" });
      }
    }
  }

  render() {
    return (
      <div>
        {/*Navigation*/}
        <ScrollToTop/>

        <nav ref={(r)=>this.ref=r} class= {"navbar navbar-expand-lg navbar-light fixed-top py-3 mainNav " +this.state.className} >
            <div class="container">
                <a class="navbar-brand js-scroll-trigger" href="#page-top">arm IT</a>
                {/*<button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>*/}
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto my-2 my-lg-0">
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#services">Services</a></li>
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">About</a></li>
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#portfolio">Portfolio</a></li>
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        {/* Masthead*/}
        <header id="page-top" class="masthead">
            <div class="container h-100">
                <div class="row h-100 align-items-center justify-content-center text-center">
                    <div class="col-lg-10 align-self-end">
                        <h1 class="text-white font-weight-bold">arm IT</h1>
                        <hr class="divider my-4" />
                    </div>
                    <div class="col-lg-8 align-self-baseline">
                        <p class="text-white-75 font-weight-light mb-5">Hi! I'm a freelance Web-Developer with more than 10 years of experience in the sector. <br/> If you need support or to create or modify a website you can contact me!</p>
                        <a class="btn btn-primary btn-xl js-scroll-trigger" href="#contact">Contact Me</a>
                    </div>
                </div>
            </div>
        </header>

        {/* Services section*/}
        <section class="page-section" id="services">
            <div class="container">
                <h2 class="text-center mt-0">My Services</h2>
                <hr class="divider my-4 light " />
                <div class="row">
                    <div class="col-lg-3 col-md-6 text-center">
                        <div class="mt-5">
                        <FontAwesomeIcon class="fas fa-1x text-primary fa-globe  mb-4" icon={faLaptopCode} />
                            <i class="fas fa-4x fa-gem text-primary mb-4"></i>
                            <h3 class="h4 mb-2">Web Apps</h3>
                            <p class="text-muted mb-0">Websites, Progressive Web Apps, Personal sites, Blogs, and many more </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center">
                        <div class="mt-5">
                        <FontAwesomeIcon class="fas fa-1x fa-globe text-primary mb-4" icon={faBullhorn} />
                            <i class="fas fa-4x fa-laptop-code text-primary mb-4"></i>
                            <h3 class="h4 mb-2">Digital Campaings</h3>
                            <p class="text-muted mb-0">Web sites for digital campaigning, petitions, etc</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center">
                        <div class="mt-5">
                          <FontAwesomeIcon class="fas fa-1x fa-globe text-primary mb-4" icon={faServer} />
                            <i class="fas fa-4x fa-heart text-primary mb-4"></i>
                            <h3 class="h4 mb-2">Hosting Support</h3>
                            <p class="text-muted mb-0">Support and assistance for hosting your website</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center">
                        <div class="mt-5">
                        <FontAwesomeIcon class="fas fa-1x fa-globe text-primary mb-4" icon={faLaptopMedical} />
                            <i class="fas fa-4x fa-globe text-primary mb-4"></i>
                            <h3 class="h4 mb-2">Office Software Support</h3>
                            <p class="text-muted mb-0">Support in Brussels for small - medium companies, offices, shops, etc</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* About section*/}
        <section class="page-section bg-primary" id="about">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="text-white mt-0">Working with the most widely used technologies</h2>
                        <hr class="divider light my-4" />
                        <SliderTech/>
                        <hr class="divider light my-4" />
                        <p class="text-white-50 mb-4">
                            I work with the main web technologies in the sector: React & Javascript, Wordpress & PHP, HTML5 & CSS3 and many more.
                        </p>
                        {/*<!--<a class="btn btn-light btn-xl js-scroll-trigger" href="#services">Get Started!</a>-->*/}
                    </div>
                </div>
            </div>
        </section>
        {/* Projects Carrousel section*/}
        <section class="page-section" id="portfolio">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                      <h2 class="mt-0">Projects & Collaborations</h2>
                      <hr class="divider my-4" />
                      <SimpleSlider/>
                      <hr class="divider my-4" />
                        <p class="text-muted mb-5">Have a look at my latest projects and collaborations: Web apps, campaigns, blogs, personal websites and many more ...</p>
                    </div>
                </div>
            </div>
        </section>
        {/* Contact section*/}
        <section class="page-section bg-dark text-white" id="contact">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="mt-0">Let's Get In Touch!</h2>
                        <hr class="divider my-4" />
                        <p class="text-muted mb-5">Ready to start your next project? Leave me a message using the contact form and I will get back to you as soon as possible!</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                        <i class="fas fa-phone fa-3x mb-3 text-muted"></i>
                        <ul class="list-unstyled mb-0">
                          <li>
                            <FontAwesomeIcon icon={faPhone} />
                            <p>+32 485 99 66 83 <br/>
                            +34 629 74 25 12</p>
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <br/>
                            <p>info @ armit.es</p>
                          </li>
                          <li>
                          <img alt="Brussels Logo" className="brxLogo" src={require("../../assets/img/brx_white.svg")} />
                              <p>Located in Brussels, Belgium</p>
                          </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 mr-auto text-center">
                        <i class="fas fa-envelope fa-3x mb-3 text-muted"></i>
                        <ContactForm />
                        <div class="status"></div>
                        {/*<a class="d-block" href="mailto:contact@yourwebsite.com">contact@yourwebsite.com</a>*/}
                    </div>
                </div>
            </div>
        </section>
        {/* Portfolio section
        <section id="portfolio">
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-4 col-sm-6">
                        <a class="portfolio-box" href={"assets/img/portfolio/fullsize/1.jpg"}
                            ><img class="img-fluid" src={require("./assets/img/portfolio/thumbnails/1.jpg")} alt="" />
                            <div class="portfolio-box-caption">
                                <div class="project-category text-white-50">Category</div>
                                <div class="project-name">Project Name</div>
                            </div></a
                        >
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <a class="portfolio-box" href={"/assets/img/portfolio/fullsize/2.jpg"}
                            ><img class="img-fluid" src={require("./assets/img/portfolio/thumbnails/2.jpg")} alt="" />
                            <div class="portfolio-box-caption">
                                <div class="project-category text-white-50">Category</div>
                                <div class="project-name">Project Name</div>
                            </div></a
                        >
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <a class="portfolio-box" href={"./assets/img/portfolio/fullsize/3.jpg"}
                            ><img class="img-fluid" src={require("./assets/img/portfolio/thumbnails/3.jpg")} alt="" />
                            <div class="portfolio-box-caption">
                                <div class="project-category text-white-50">Category</div>
                                <div class="project-name">Project Name</div>
                            </div></a
                        >
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <a class="portfolio-box" href={"assets/img/portfolio/fullsize/4.jpg"}
                            ><img class="img-fluid" src={require('./assets/img/portfolio/thumbnails/4.jpg')} alt="" />
                            <div class="portfolio-box-caption">
                                <div class="project-category text-white-50">Category</div>
                                <div class="project-name">Project Name</div>
                            </div></a
                        >
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <a class="portfolio-box" href={"assets/img/portfolio/fullsize/5.jpg"}
                            ><img class="img-fluid" src={require("./assets/img/portfolio/thumbnails/5.jpg")} alt="" />
                            <div class="portfolio-box-caption">
                                <div class="project-category text-white-50">Category</div>
                                <div class="project-name">Project Name</div>
                            </div></a
                        >
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <a class="portfolio-box" href={"assets/img/portfolio/fullsize/6.jpg"}
                            ><img class="img-fluid" src={require("./assets/img/portfolio/thumbnails/6.jpg")} alt="" />
                            <div class="portfolio-box-caption p-3">
                                <div class="project-category text-white-50">Category</div>
                                <div class="project-name">Project Name</div>
                            </div></a >
                    </div>
                </div>
            </div>
        </section>*/}
        {/* Call to action section
        <section class="page-section bg-dark text-white">
            <div class="container text-center">
                <h2 class="mb-4">Free Download at Start Bootstrap!</h2>
                <a class="btn btn-light btn-xl" href="https://startbootstrap.com/themes/creative/">Download Now!</a>
            </div>
        </section>*/}
        {/* Contact section
        <section class="page-section bg-dark text-white" id="contact">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="mt-0">Let's Get In Touch!</h2>
                        <hr class="divider my-4" />
                        <p class="text-muted mb-5">Ready to start your next project with us? Give us a call or send us an email and we will get back to you as soon as possible!</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                        <i class="fas fa-phone fa-3x mb-3 text-muted"></i>
                        <div>+1 (555) 123-4567</div>
                    </div>
                    <div class="col-lg-4 mr-auto text-center">
                        <i class="fas fa-envelope fa-3x mb-3 text-muted"></i
                        >/* Make sure to change the email address in BOTH the anchor text and the link target below!<a class="d-block" href="mailto:contact@yourwebsite.com">contact@yourwebsite.com</a>
                    </div>
                </div>
            </div>
        </section>*/}
        {/* Footer*/}
        <footer class="bg-light py-5">
            <div class="container"><div class="small text-center text-muted">arm IT - Copyright © 2020 - Built with: React + Bootstrap</div></div>
        </footer>
      </div>
    )
  }
}

export default BootstrapTpl;
