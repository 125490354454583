import React, {Component} from 'react';

import './ContactForm.css';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      formErrors: {email: ''},
      emailValid: false,
      formValid: false
    }
  }
  componentDidMount() {
    document.getElementById("sendMsgBtn").addEventListener("click", this.handleSendToTelegram);
    document.getElementById("email").addEventListener("change", this.validateForm);
  }

  validateForm = () => {
    console.log(document.getElementById("email").value);
    
  }

  handleSendToTelegram = (e) => {

    if (this.state.formValid) {
      console.log("Sending telegram message");
      e.preventDefault();
      const name = document.getElementById("name").value;
      const email = document.getElementById("email").value;
      const subject = document.getElementById("subject").value;
      const message = document.getElementById("message").value;
    
      fetch('https://api.armit.es/sendBotMsg.php',{
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }),
            body: `message=${message}&name=${name}&email=${email}&subject=${subject}`,// <-- Post parameters
          })
          .then((response) => {
            alert("Message sent !\nThanks for contacting me!\nI will get back to you as soon as possible.");
            response.text()
          })
          .then((responseText) => {
            alert("Message sent !\nThanks for contacting me!\nI will get back to you as soon as possible.");
          })
          .catch((error) => {
            alert("Message sent !\nThanks for contacting me!\nI will get back to you as soon as possible.");
            console.error(error);
          });
    } else {alert("Something is missing")}
    
  }
  render() {
    return (
          <div>
              <form id="contact-form" name="contact-form" action="mail.php" method="POST">               
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="md-form mb-0">
                                        <input placeholder="Your name" type="text" id="name" name="name" class="form-control"/>
                                        <br/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="md-form mb-0">
                                        <input placeholder="Your email" type="text" id="email" name="email" required class="form-control"/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="md-form mb-0">
                                        <input placeholder="Subject" type="text" id="subject" name="subject" class="form-control"/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="md-form">
                                        <textarea placeholder="Your message" type="text" id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="text-center text-md-left">
                          <button id="sendMsgBtn" class="btn btn-primary">Send</button>
                        </div>
            
          </div>
    )
  }
}
